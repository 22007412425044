import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => {
  return (
    <div className="py-16 px-8 md:px-12">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-8">
        <div className="col-span-2">
          <Link to="/">
            <img src={'img/rbs-logo.svg'} className="h-7 md:h-9" alt="Logo" />
          </Link>
        </div>


        <div className="">
          <h3 className="body-s uppercase mb-2">Sprint</h3>
          <ul>
            <li className="body-l text-rbs-black"><Link to="/method">Method</Link></li>
            <li className="body-l text-rbs-black"><Link to="/process">Process</Link></li>
            <li className="body-l text-rbs-black"><Link to="/results">Results</Link></li>
            <li className="body-l text-rbs-black"><Link to="/cases">Case Studies</Link></li>
            <li className="body-l text-rbs-black"><Link to="/pricing">Pricing</Link></li>
          </ul>
        </div>

        <div>
          <h3 className="body-s uppercase mb-2">About &amp; Contact</h3>
          <ul>
            <li className="body-l text-rbs-black"><Link to="/about">About Us</Link></li>
            <li className="body-l text-rbs-black"><Link to="/blog">Blog</Link></li>
            <li className="body-l text-rbs-black"><a href="https://remotebrandsprint.substack.com" target="_blank" rel="noreferrer">Newsletter</a></li>
            <li className="body-l text-rbs-black"><Link to="/faq">FAQ</Link></li>
            <li className="body-l text-rbs-black"><Link to="/contact">Contact</Link></li>
          </ul>
        </div>

      </div>

    </div>


  )
}

export default Footer