import React from 'react'
import { Link } from 'gatsby'

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

class HeaderPage extends React.Component {

    state = {
        menuActive: false,
    }

    render() {
        return (
            <div>

                <div>
                    <div className={'fixed w-full h-24 px-8 md:px-12 z-40 ' + (this.state.menuActive ? "bg-none" : "bg-white")}>
                        <div class="flex">
                            <div className="flex-none flex items-center">
                                <Link to="/">
                                    <img src={'/img/rbs-logo.svg'} className="h-7 md:h-9" alt="Logo" />
                                </Link>
                            </div>
                            <div className="flex-grow h-24 flex items-center">
                                <div className="w-full text-right">
                                    <div className={this.state.menuActive ? "hidden" : "inline-block"}>
                                        <div className="hidden md:inline-block">
                                            <Link to="/method" className="body mx-2 text-rbs-black" activeClassName="">Method</Link>
                                            <Link to="/process" className="body mx-2 text-rbs-black" activeClassName="text-rbs-black">Process</Link>
                                            <Link to="/results" className="body mx-2 text-rbs-black" activeClassName="text-rbs-black">Results</Link>
                                            <Link to="/cases" className="body mx-2 text-rbs-black" activeClassName="text-rbs-black">Cases</Link>
                                            <Link to="/pricing" className="body mx-2 text-rbs-black" activeClassName="text-rbs-black">Pricing</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-none flex items-center">
                                <div className={this.state.menuActive ? "hidden" : "inline-block"}>
                                    <button
                                        className="btn-sm btn-none"
                                        onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="24" />
                                            <path d="M2 20H22" stroke="#2E3038" />
                                            <path d="M2 4H22" stroke="#2E3038" />
                                            <path d="M2 12H22" stroke="#2E3038" />
                                        </svg>
                                    </button>
                                </div>

                                <div className={this.state.menuActive ? "inline-block" : "hidden"}>
                                    <button
                                        className="btn-sm btn-none"
                                        onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="24" />
                                            <path d="M2 22L22 2" stroke="#2E3038" />
                                            <path d="M2 2L22 22" stroke="#2E3038" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className={this.state.menuActive ? "block" : "hidden"}>
                    <div className="fixed w-full h-full bg-blur bg-white bg-opacity-95 z-30 pt-24">

                        <div class="p-12 grid grid-cols-2 gap-0">

                            <div>
                                <h3 className="body uppercase mb-4">Sprint</h3>
                                <ul>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/method">Method</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/process">Process</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/results">Results</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/cases">Case Studies</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/pricing">Pricing</Link></li>
                                </ul>
                                
                                
                                
                                
                            </div>

                            <div>
                                <h3 className="body uppercase mb-4">About &amp; Contact</h3>
                                <ul>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/about">About Us</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/blog">Blog</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><a href="https://remotebrandsprint.substack.com" target="_blank" rel="noreferrer">Newsletter</a></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/faq">FAQ</Link></li>
                                    <li className="body-xl mb-2 text-rbs-black"><Link to="/contact">Contact</Link></li>
                                </ul>
                              
                                
                            </div>

                        </div>
                    </div>




                </div>


                <div className="h-24"></div>

            </div>
        )
    }
}

export default HeaderPage